import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';

import Card from '../components/Card';
import Signature from '../components/Signature';
import Title from '../components/Title';

const AboutPage = () => (
  <Layout
    description="Engineering Manager, Instructor and Web Developer with over 20 years
  experience."
    pagetitle="About"
  >
    <PageHeader>
      <Title>About Me</Title>
    </PageHeader>
    <Card>
      <p>
        <StaticImage
          alt="A photo of Joshua Studley"
          height={200}
          src="../images/Joshua_Studley.jpg"
          width={200}
        />
      </p>
      <p>
        Engineering Manager, Instructor and Web Developer with over 20 years
        experience. I lead teams to help build high profile websites for clients
        such as{' '}
        <a href="https://www.facebook.com/" rel="noreferrer">
          Facebook
        </a>
        . I built high traffic sites for{' '}
        <a href="https://www.motortrend.com/" rel="noreferrer">
          MotorTrend
        </a>
        ,{' '}
        <a href="https://www.kbb.com/" rel="noreferrer">
          Kelley Blue Book
        </a>{' '}
        and the{' '}
        <a href="https://www.ocregister.com/" rel="noreferrer">
          OC Register
        </a>
        . My passion for mentoring led me to teach full-stack developer classes
        for{' '}
        <a href="https://ce.uci.edu/" rel="noreferrer">
          UCI
        </a>{' '}
        and{' '}
        <a href="https://www.uclaextension.edu/" rel="noreferrer">
          UCLA
        </a>
        . When I'm not building websites, I'm building websites.
      </p>

      <p>
        You can email me at <strong>josh&#xFF20;studley&#x2024;dev</strong>
      </p>

      <h2>About this site</h2>

      <p>
        The current version of this site is built with{' '}
        <a href="https://www.gatsbyjs.com/" rel="noreferrer">
          Gatsby
        </a>{' '}
        (
        <a href="https://reactjs.org/" rel="noreferrer">
          React
        </a>
        ) and hosted on{' '}
        <a href="https://www.netlify.com/" rel="noreferrer">
          Netlify
        </a>
        . The content is delivered using{' '}
        <a href="https://www.sanity.io/" rel="noreferrer">
          Sanity.io
        </a>{' '}
        (CMS). Code is hosted on a private{' '}
        <a href="https://github.com/" rel="noreferrer">
          GitHub
        </a>{' '}
        repo (for now).
      </p>

      <p>
        <strong>Minutiae:</strong> CSS is written in{' '}
        <a href="https://sass-lang.com/" rel="noreferrer">
          Sass
        </a>{' '}
        and added to React components (CSS-in-JS) through{' '}
        <a href="https://github.com/css-modules/css-modules" rel="noreferrer">
          CSS Modules
        </a>{' '}
        and{' '}
        <a href="https://github.com/JedWatson/classnames" rel="noreferrer">
          Classnames
        </a>
        .{' '}
        <a href="https://www.typescriptlang.org/" rel="noreferrer">
          Typescript
        </a>{' '}
        is used to keep my code in line. Social share images are generated with{' '}
        <a
          href="https://developers.google.com/web/tools/puppeteer"
          rel="noreferrer"
        >
          Puppeteer
        </a>
        .
      </p>
      <hr />
      <p>
        <small>
          The views expressed by me are not necessarily the views of my
          employer.
        </small>
      </p>
      <Signature />
    </Card>
  </Layout>
);

export default AboutPage;
